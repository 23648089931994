<template>
    <div>
        <div v-if="showNavi" class="form-row mt-2">
            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <form onsubmit="return false" class="form-inline float-right">
                            <div class="form-group">
                                <select id="IdSucursal" v-model="IdSucursalGlob" @change="listTotales();" v-show="ShowCmbSucursales" class="form-control form-select mr-1">
                                    <option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
                                        {{ item.Nombre }}
                                    </option>
                                </select>

                                <div class="form-group mx-sm-2">
                                    <v-date-picker v-model="objFiltroFechasGlob" @input="listTotales()" :masks="masks" :popover="{ visibility: 'focus' }" :disabled-dates='{ weekdays: [1] }' locale="es" :max-date="new Date()">
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <input class="form-control cal" placeholder="Seleccione una Fecha" :value="inputValue" v-on="inputEvents" readonly/>
                                        </template>
                                    </v-date-picker>
                                </div>

                                <button type="button" @click="listTotales" v-b-tooltip.hover.Top title="Recargar" class="btn btn-primary btn-sm mr-2">
                                    <i class="far fa-redo"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-row mt-2">

            <div v-if="security.ViewGrapRegistroCorbros" class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="row">
                                    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <h3>Registro de Cobros</h3>
                                    </div>
                                    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right">

                                    </div>
                                </div>
                                <hr>
                                <CLoaderVue :pConfigLoad="CLoadCobros">
                                    <template slot="BodyFormLoad">

                                        <div class="row">
                                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="">
                                                    <p class="text-secondary mb-n1"><i>El dia de hoy (+ multas cobradas)</i></p>
                                                    <h1 class="text-info">{{montoRealCobrado}}</h1>
                                                </div>

                                                <div class="mt-2">
                                                    <p class="text-secondary mb-n1"><i>El dia de hoy (sin multas)</i></p>
                                                    <h1 class="text-info">{{globalDiaSinMultas}}</h1>
                                                </div>
                                                <div class="mt-2">
                                                    <p class="text-secondary mb-n1"><i>Total prestado (Cantidad prestada + interes)</i></p>
                                                    <h1 class="text-info">{{totalGlobalPrestadoInteres}}</h1>
                                                </div>
                                                <div class="mt-2">
                                                    <p class="text-secondary mb-n1"><i>Cobrado (Cantidad cobrada hasta hoy)</i></p>
                                                    <h1 class="text-info">{{totalGlobalPagado}}</h1>
                                                </div>
                                                <div class="mt-2">
                                                    <p class="text-secondary mb-n2"><i>Por Cobrar (Cantidad prestada + interes)</i></p>
                                                    <h1 class="text-info">{{globalFaltanteCobrar}}</h1>
                                                </div>
                                            </div>
                                        </div>

                                    </template>
                                </CLoaderVue>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="security.ViewGraphCobrosDia" class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="row">
                                    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <h3>Cobros del dia</h3>
                                    </div>
                                    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right">
                                        <span>Total de cobros</span>
                                    </div>
                                </div>
                                <CLoaderVue :pConfigLoad="CLoadCobros">
                                    <template slot="BodyFormLoad">
                                        <div class="row">
                                            <!-- ACTUAL -->
                                            <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                <h2>{{totalCobrosRealizados}} <small>Realizados</small></h2>
                                            </div>
                                            <!-- PLAN -->
                                            <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right">
                                                <h3>{{totalCobros}}</h3>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row mt-1">

                                            <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                <div id="chart">
                                                    <apexchart type="radialBar" height="298" :options="options" :series="series"></apexchart>
                                                </div>
                                            </div>

                                            <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                <div class="mt-4">
                                                    <p class="text-secondary mb-n1"><i>Monto Estimado</i></p>
                                                    <h1 class="text-info">{{montoEstimado}}</h1>

                                                </div>
                                                <div class="mt-2">
                                                    <p class="text-secondary mb-n1"><i>Monto Real Cobrado</i></p>
                                                    <h1 class="text-info">{{montoRealCobrado}}</h1>

                                                </div>

                                                <div class="mt-2">
                                                    <p class="text-secondary mb-n1"><i>Monto Prod. General</i></p>
                                                    <h1 class="text-info">{{totalProductividad}}</h1>

                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </CLoaderVue>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="security.ViewGraphDesgloceCobros" class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="card card-numer">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="row">
                                    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <h3>Desgloce de cobros</h3>
                                    </div>
                                    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right">
                                        <span></span>
                                    </div>
                                </div>

                                <div class="row">
                                    <!-- ACTUAL -->
                                    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <h5></h5>
                                    </div>
                                    <!-- PLAN -->
                                    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right">
                                        <h6></h6>
                                    </div>

                                </div>
                                <hr>
                                <CLoaderVue :pConfigLoad="CLoadCobros">
                                    <template slot="BodyFormLoad">
                                        <div class="row">
                                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div id="chart">
                                                    <apexchart v-if="showCreditos" type="bar" height="290" :options="chartOptions3" :series="series3"></apexchart>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </CLoaderVue>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="form-row mt-2">

            <div v-if="security.ViewGraphCreditosAcumulados" class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="row">
                                    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <h3>Créditos Acumulados</h3>
                                    </div>
                                    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right">

                                    </div>
                                </div>
                                <hr>
                                <CLoaderVue :pConfigLoad="CLoadCreditos">
                                    <template slot="BodyFormLoad">
                                        <div class="row">
                                            <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                <div id="chart">
                                                    <apexchart v-if="showCreditos" type="pie" width="399" :options="chartPrestamosEstatus" :series="totalesPrestamosEstatus"></apexchart>
                                                </div>
                                            </div>

                                            <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                <div class="row">

                                                    <div class="col-6 col-xs-6 col-sm-6 col-md-8 col-lg-8 mb-2">
                                                        <div class="row justify-content-center">
                                                            <div class="col-12 col-sm-12 col-md-8 col-lg-8">
                                                                <p class="text-secondary mb-n1"><i>Créditos Activos</i></p>
                                                                <h1 class="text-info">{{creditosActivos}}</h1>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>

                                                    <div class="col-6 col-xs-6 col-sm-6 col-md-8 col-lg-8 mb-2">
                                                        <div class="row justify-content-center">
                                                            <div class="col-12 col-sm-12 col-md-8 col-lg-8">
                                                                <p class="text-secondary mb-n1"><i>Total Acumulado de Creditos</i></p>
                                                                <h1 class="text-info">{{creditosAcumulados}}</h1>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-6 col-xs-6 col-sm-6 col-md-8 col-lg-8 mb-2">
                                                        <div class="row justify-content-center">
                                                            <div class="col-12 col-sm-12 col-md-8 col-lg-8">
                                                                <p class="text-secondary mb-n1"><i>Total Creditos Liquidados</i></p>
                                                                <h1 class="text-info">{{creditosLiquidados}}</h1>
                                                            </div>
                                                        </div>

                                                    </div>

                            
                                                </div>
                                            </div>

                                        <!--    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                <div class="row">
                                                    <div class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2">
                                                    </div>
                                                    <div class="col-12 col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                                        <div class="mt-4">
                                                            <p class="text-secondary mb-n1"><i>Créditos Activos</i></p>
                                                            <h1 class="text-info">{{creditosActivos}}</h1>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2">
                                                    </div>
                                                    <div class="col-12 col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                                        <div class="mt-2">
                                                            <p class="text-secondary mb-n1"><i>Total Acumulado de Creditos</i></p>
                                                            <h1 class="text-info">{{creditosAcumulados}}</h1>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2">
                                                    </div>
                                                    <div class="col-12 col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                                        <div class="mt-2">
                                                            <p class="text-secondary mb-n1"><i>Total Creditos Liquidados</i></p>
                                                            <h1 class="text-info">{{creditosLiquidados}}</h1>

                                                        </div>
                                                    </div>
                                                </div>



                                            </div> -->
                                        </div>
                                    </template>
                                </CLoaderVue>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="security.ViewGrahpEntregasCredito" class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="row">
                                    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <h3>Entregas de Créditos</h3>
                                    </div>
                                    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right">
                                    </div>
                                </div>
                                <hr>
                                <CLoaderVue :pConfigLoad="CLoadCreditos">
                                    <template slot="BodyFormLoad">
                                        <div class="row">
                                            <div class="col-12 col-xs-12 col-sm-12 col-md-7 col-lg-7">
                                                <div id="chart">
                                                    <apexchart v-if="showCreditos" type="bar" height="284" :options="chartOptions2" :series="series2"/>
                                                </div>
                                            </div>
                                            <div class="col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5">
                                                <div class="row">
                                                    <div class="col-12 col-xs-12 col-sm-12 col-md-1 col-lg-1">
                                                    </div>
                                                    <div class="col-12 col-xs-12 col-sm-12 col-md-11 col-lg-11">
                                                        <div class="mt-4">
                                                            <p class="text-secondary mb-n1"><i>Créditos Entregados (Hoy)</i></p>
                                                            <h1 class="text-info">{{entregadosDia}}</h1>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-xs-12 col-sm-12 col-md-1 col-lg-1">
                                                    </div>
                                                    <div class="col-12 col-xs-12 col-sm-12 col-md-11 col-lg-11">
                                                        <div class="mt-2">
                                                            <p class="text-secondary mb-n1"><i>Entregas Pendientes por Realizar</i></p>
                                                            <h1 class="text-info">{{entregasPendientes}}</h1>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-xs-12 col-sm-12 col-md-1 col-lg-1">
                                                    </div>
                                                    <div class="col-12 col-xs-12 col-sm-12 col-md-11 col-lg-11">
                                                        <div class="mt-2">
                                                            <p class="text-secondary mb-n1"><i>Total Entregas de la Semana</i></p>
                                                            <h1 class="text-info">{{totalEntregasSemana}}</h1>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12 col-xs-12 col-sm-12 col-md-1 col-lg-1">
                                                    </div>
                                                    <div class="col-12 col-xs-12 col-sm-12 col-md-11 col-lg-11">
                                                        <div class="mt-2">
                                                            <p class="text-secondary mb-n1"><i>Monto Total Semanal Entregado</i></p>
                                                            <h1 class="text-info">{{montoTotalEntregas}}</h1>

                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        
                                        </div>
                                    </template>
                                 </CLoaderVue>
                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div v-if="security.ViewGraphProductividadGeneral" class="row mt-2">
            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="row">
                                    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <h3>Productividad General del Día</h3>
                                    </div>
                                    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <form onsubmit="return false" class="form-inline float-right">
                                            <div class="form-group">

                                                <select id="IdSucursal" v-model="IdSucursalProd" @change="tblProductividad();" v-show="ShowCmbSucursales"  class="form-control form-select mr-1">
                                                    <option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
                                                        {{ item.Nombre }}
                                                    </option>
                                                </select>

                                                <div class="form-group mx-sm-2">
                                                    <v-date-picker v-model="objFiltroFechas" @input="tblProductividad()" :masks="masks" :popover="{ visibility: 'focus' }" :disabled-dates='{ weekdays: [1] }' locale="es" :max-date="stopDate()">
                                                        <template v-slot="{ inputValue, inputEvents }">
                                                            <input class="form-control cal" placeholder="Seleccione una Fecha" :value="inputValue" v-on="inputEvents" readonly/>
                                                        </template>
                                                    </v-date-picker>
                                                </div>

                                                <button type="button" @click="tblProductividad" v-b-tooltip.hover.Top title="Recargar" class="btn btn-primary btn-sm mr-2">
                                                    <i class="far fa-redo"></i>
                                                </button>


                                            </div>
                                        </form>

                                    </div>

                                </div>
                                <div class="row mt-2">
                                    <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <CLoader :pConfigLoad="ConfigLoadProdTbl">
                                            <template slot="BodyFormLoad">
                                                <table class="table table-responsive-lg">
                                                    <thead>
                                                    <tr>
                                                        <th>Gestor</th>
                                                        <th>Ruta</th>
                                                        <th>Estatus Dia</th>
                                                        <th>Mont. Estim. Cobro</th>
                                                        <th>Mont. Real Cobrado</th>
                                                        <th>Mont. Productividad</th>
                                                        <th class="text-center">Clientes</th>
                                                        <th class="text-center">Productividad Actual</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-for="(item,index) in listaProductividad" :key="index">
                                                        <td>{{item.NombreCompleto}}</td>
                                                        <td>{{item.NombreRuta}}</td>
                                                        <td>
                                                            <span v-if="item.EstatusDia == ''" class="badge badge-secondary">No Generado</span>
                                                            <span v-else-if="item.EstatusDia == 'Pendiente'" class="badge badge-primary">Pendiente</span>
                                                            <span v-else-if="item.EstatusDia == 'EnCurso'" class="badge badge-success">En Cobranza</span>
                                                            <span v-else-if="item.EstatusDia == 'EnCierre'" class="badge badge-dark">En Precorte</span>
                                                            <span v-else-if="item.EstatusDia == 'EnCorte'" class="badge badge-warning">En Corte</span>
                                                            <span v-else-if="item.EstatusDia == 'AlDia'" class="badge badge-danger">Cerrado</span>
                                                        </td>
                                                        <td>{{$formatNumber(item.MontoEstimadoCobrar,'$')}}</td>
                                                        <td>{{$formatNumber(item.MontoRealCobrado, '$')}}</td>
                                                        <td>{{$formatNumber(item.TotalProductividad, '$')}}</td>
                                                        <td class="text-center">{{item.NumTotalCobrosRealizados}}/{{ item.TotalElementos}}</td>
                                                        <td>
                                                            <b-progress variant="primary" height="17px" :value="parseFloat(item.ProcentProductividad)" max="100" class="" :precision="2" show-progress/>
                                                        </td>
                                                    </tr>

                                                    <CSinRegistros :pContIF="listaProductividad.length" :pColspan="8"></CSinRegistros>
                                                    </tbody>
                                                </table>
                                            </template>
                                        </CLoader>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </div>
</template>

<script>
import CSinRegistros from "../../../components/CSinRegistros";
import moment from "moment";
import CLoaderVue from '../../../components/CLoader.vue';

export default {
    name:  "ListaInicio",
    props: [],
    components: {
        CSinRegistros,
        CLoaderVue
    },
    data() {
        return {
            ListaEstadosArray: [],
            ListaSucursalesArray:[],
            ShowCmbSucursales: false,
            IdSucursalProd:0,
            IdSucursalGlob:0,
            IdSucursalTotales: 0,
            security:{},
            showNavi: false,

            CLoadCobros:{
                ShowLoader: true,
                ClassLoad:  true,
            },
            CLoadCreditos:{
                ShowLoader: true,
                ClassLoad:  true,
            },

            masks: {
                input: "YYYY-MM-DD",
            },
            objFiltroFechas: new Date(),
            objFiltroFechasGlob: {},
            FiltroFecha:'',
            FiltroFechaGlob:'',
            ConfigLoadProdTbl:{
                ShowLoader: true,
                ClassLoad:  true,
            },

            // COBROS DEL DIA *******************************
            showCobros: false,
            totalCobros: 0,
            totalCobrosRealizados: 0,
            montoEstimado: 0,
            montoRealCobrado: 0,
            totalProductividad: 0,
            entregadosDia: 0,
            entregasPendientes: 0,

            showTblProd: false,
            listaProductividad:[],

            series: [0],
            options: {
                chart: {
                    height: 350,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '60%',
                        }
                    },
                },
                labels: ['Cumplimiento'],
            },


            // CREDITOS ACUMULADOS *******************************
            creditosActivos: 0,
            creditosAcumulados:0,
            creditosLiquidados:0,
            totalEntregasSemana:0,
            montoTotalEntregas:0,
            showCreditos: false,
            series2: [
                {   name: 'Cred. Entregados',
                    data: [0, 0, 0, 0, 0, 0, 0]
                }
            ],

            chartOptions2: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false,
                    },
                },

                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    offsetX: -6,
                    style: {
                        fontSize: '12px',
                        colors: ['#fff']
                    }
                },
                stroke: {
                    show: true,
                    width: 1,
                    colors: ['#fff']
                },
                tooltip: {
                    shared: true,
                    intersect: false
                },
                xaxis: {
                    categories: ["", "", "", "", "", ""],
                },
            },


            // DESGLOCE DE MONTO COBRADO
            series3: [{
                name: '',
                data: [0, 0, 0,0]
            }],
            chartOptions3: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                        barHeight: '80%',
                        distributed: true,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    width: 1,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: ['Total','Mont. Produc.','Excedente','Multas'],

                },
                tooltip:{
                    y: {
                        formatter: function (val) {
                            return '$' + val;
                        }
                    }
                },

                colors: ['#FF640A','#0F3F87','#216CB8','#297DCA'],
            },


            totalesPrestamosEstatus: [0,0,0,0,0,0,0],
            chartPrestamosEstatus: {
                chart: {
                    width: 350,
                    type: 'pie',
                },
                labels: ['','','','','','',''],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'rigth'
                        }
                    }
                }],
                legend:{
                    position: 'bottom',
                    horizontalAlign: 'center',
                },
                //colors: ['#007bff','#ffc107','#17a2b8','#FF5733','#28a745','#9264D7','#dc3545'],
                colors: ['#007bff','#ffc107','#17a2b8','#FF5733','#28a745','#dc3545'],
            },

            // TOTALES GLOBALES
            totalGlobalPrestadoNeto: 0,
            totalGlobalPrestadoInteres: 0,
            totalGlobalPagado: 0,
            globalFaltanteCobrar: 0,
            globalDiaSinMultas: 0,





        };
    },
    methods: {

        async getSecureView() {
            await this.$http.get("permisosseccion", {
                    params: {
                        KeyMenu: 'CLAVEINICIO'
                    },
                })
                .then((res) => {
                    this.security = res.data.data;
                    
                    

                    if(this.security.ViewGrahpEntregasCredito || this.security.ViewGraphCobrosDia || this.security.ViewGraphDesgloceCobros || this.security.ViewGraphCreditosAcumulados || this.security.ViewGrapRegistroCorbros) {
                        this.showNavi = true;
                        this.grapCobros();
                        this.grapCreditos();
                    }
                    
                    if(this.security.ViewGraphProductividadGeneral){
                        this.tblProductividad();
                    }

                    /*if(this.security.ViewGrahpEntregasCredito){
                        
                    }
                    if(this.security.ViewGraphCobrosDia){

                    }
                    if(this.security.ViewGraphDesgloceCobros){

                    }*/
                    /*if(this.security.ViewGraphCreditosAcumulados){

                    }*/
                    /*if(this.security.ViewGrapRegistroCorbros){

                    }*/
                });
        },


        async ListaEstados() {
            await this.$http
                .get("estados", {
                    params: {},
                })
                .then((res) => {
                    this.ListaEstadosArray = res.data.data;
                    if (this.ListaEstadosArray != undefined) {
                        sessionStorage.setItem("ListaEstadosArray", JSON.stringify(this.ListaEstadosArray));
                    }
                });
        },

        ListaSucursales() {
            this.$http.get("sucursales", {
                params: {
                    simple: 1
                },
            })
                .then((res) => {
                    let response = res.data.dataSuc;
                    this.ListaSucursalesArray = res.data.data;

                    if(response.EsAdmin == 0)
                    {
                        this.IdSucursalProd = response.IdSucursal;
                        this.IdSucursalGlob = response.IdSucursal;
                    }
                    else
                    {
                        this.ShowCmbSucursales = true;

                        if(this.ListaSucursalesArray.length>0)
                        {
                            this.IdSucursalProd = this.ListaSucursalesArray[0].IdSucursal;
                            this.IdSucursalGlob = this.ListaSucursalesArray[0].IdSucursal;
                        }
                    }
                }).finally(()=>{
                    //this.getSecureView();
                
            });
        },

        listTotales(){
            this.grapCobros();
            this.grapCreditos();

        },

        async grapCobros() {

            
            this.CLoadCobros.ShowLoader = true;
            this.showCobros             = false;
            this.totalCobros            = 0;
            this.totalCobrosRealizados  = 0;
            this.montoEstimado          = 0;
            this.montoRealCobrado       = 0;
            this.series                 = [0];
            this.series3[0].data = [0, 0, 0, 0];

            this.FiltroFechaGlob = moment(this.objFiltroFechasGlob).format('YYYY-MM-DD');

            await this.$http.get("dashcobros", {
                params: {
                    IdSucursal: this.IdSucursalGlob,
                    Fecha: this.FiltroFechaGlob
                },
            }).then((res) => {
                const response = res.data.data;

                this.totalCobros                = response.totalCobros;
                this.totalCobrosRealizados      = response.cobrosRealizados;
                this.montoEstimado              = this.$formatNumber(response.montoTotalEstimado,'$');
                this.montoRealCobrado           = this.$formatNumber(response.montoTotalRealCobrado,'$');
                this.totalProductividad         = this.$formatNumber(response.totalProductividad,'$');

                this.montoTotalMultas           = response.montoTotalMultas
                this.totalGlobalPrestadoNeto    = this.$formatNumber(response.totalGlobalPrestadoNeto,'$');
                this.totalGlobalPrestadoInteres = this.$formatNumber(response.totalGlobalPrestadoInteres,'$');
                this.totalGlobalPagado          = this.$formatNumber(response.totalGlobalPagado,'$');
                this.globalFaltanteCobrar       = this.$formatNumber(response.globalFaltanteCobrar,'$');
                this.globalDiaSinMultas         = this.$formatNumber(response.globalDiaSinMultas,'$');

                this.series                 = response.procentRealizado;
                this.series3[0].data        = response.dataGrapDesgloce;
             

            }).finally(() => {
                this.showCobros             = true;
                this.CLoadCobros.ShowLoader = false;
            });
        },

        async grapCreditos() {

            this.CLoadCreditos.ShowLoader = true;

            this.showCreditos                   = false;
            this.series2[0].data                = [0, 0, 0, 0, 0, 0, 0];
            this.chartOptions2.xaxis.categories = ["", "", "", "", "", ""];
            this.totalesPrestamosEstatus        = [0,0,0,0,0,0,0];
            this.chartPrestamosEstatus.labels   = ['','','','','','',''];

            this.FiltroFechaGlob = moment(this.objFiltroFechasGlob).format('YYYY-MM-DD');

            await this.$http.get("dashcreditos", {
                params: {
                    IdSucursal: this.IdSucursalGlob,
                    Fecha: this.FiltroFechaGlob
                },
            }).then((res) => {
                const response = res.data.data;
                // CREDITOS ACUMULADOS - GRAPH PASTEL
                this.creditosAcumulados             = this.$formatOnlyNumeric(response.totalPrestamos,false);
                this.creditosActivos                = this.$formatOnlyNumeric(response.creditoCobranza,false);
                this.creditosLiquidados             = this.$formatOnlyNumeric(response.creditoLiquidado,false);
                this.chartPrestamosEstatus.labels   = response.grapCreditosLabels;
                this.totalesPrestamosEstatus        = response.grapCreditosSeries;
                
                // ENTREGAS
                this.series2[0].data                = response.grapResults;
                this.chartOptions2.xaxis.categories = response.grapLabels;
                this.entregadosDia                  = response.entregadosDia;
                this.entregasPendientes             = response.entregasPendientes;
                this.totalEntregasSemana            = response.totalentregasSemana;
                this.montoTotalEntregas             = this.$formatNumber(response.montoTotalEntregas,'$');

            }).finally(() => {
                this.showCreditos               = true;
                this.CLoadCreditos.ShowLoader   = false;
            });
        },

        async tblProductividad() {
            this.showTblProd        = false;
            this.ConfigLoadProdTbl.ShowLoader = true;
            this.listaProductividad = [];
            this.FiltroFecha = moment(this.objFiltroFechas).format('YYYY-MM-DD');

            await this.$http.get("dashproductividad", {
                params: {
                    IdSucursal: this.IdSucursalProd,
                    Fecha: this.FiltroFecha
                },
            }).then((res) => {
                
                const response = res.data.data.productividadRuta;
                this.listaProductividad = response;


            }).finally(() => {
                this.ConfigLoadProdTbl.ShowLoader = false;
            });
        },

        number(value){
            return this.$formatNumber(value,'$')
        },

        numberto(value) {
            return this.$numberto(value);
        },

        stopDate(){

            const currentDate = new Date();
       
            if(currentDate.getDay() == 6) {
                 return new Date(currentDate.setDate(currentDate.getDate() + 2));

            }else {
                return new Date(currentDate.setDate(currentDate.getDate() + 1));
            
            }
        }
    },

    created() {
        this.ListaEstados();
        this.ListaSucursales();
        this.stopDate();

    },
};

</script>
