<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2">
                            <label><span style="color: black">Folio: </span>{{Prestamo.Folio}}</label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2">
                            <label><span style="color: black">Monto Pago:</span> {{Prestamo.MontoDiario}}</label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                            <label><span style="color: black">Monto Entregado:</span> {{Prestamo.MontoEntregado}}</label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                            <label><span style="color: black">IdCobranzaDiaria:</span> {{Campos.IdPrestamosPago}}</label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2">
                            <label><span style="color: black">IdPrestamo</span> {{Campos.IdPrestamo}}</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                            <label>Fecha</label>
                            <input type="text" v-model="Campos.Fecha" class="form-control">
                        </div>
                        <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                            <br>
                            <button type="button" @click="GenerarDia" class="btn btn-success">
                                Dia Siguiente
                            </button>
                        </div>
                        <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                            <label><span style="color: black">Estatus Dia: </span>{{Campos.Estatus}}</label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                            <label><span style="color: black">Dia Actual: </span>{{Campos.DiaActual}}</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                            <label>Monto</label>
                            <input type="text" v-model="Campos.Pago" @input="$formatNumber($event,Campos,'Pago')" class="form-control">
                        </div>
                        <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                            <br>
                            <button type="button" @click="PagarDia" class="btn btn-warning">
                                Realizar Pago
                            </button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                            
                        </div>
                        <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                            <br>
                            <button type="button" @click="CerrarDia" class="btn btn-danger">
                                Cerrar Dia
                            </button>
                        </div>
                    </div>

                </div>
            </form>
        </template>
    </CLoader>
    
</template>

<script>

const EmitEjecuta = 'seccionMultas';
import Configs    from '@/helpers/VarConfig.js';

export default {
    name: 'ListMultas',
    props: ['poBtnSave'],
    components: { },
    data() {
        return{
            ConfigLoad:{
                ShowLoader: false,
                ClassLoad: false,
            },
            ConfigList:{
                Title: 'Listado de Multas',
                ShowLoader: false,
                BtnNewShow: false,
                ShowTitleFirst: false,
                IsModal: true,
                EmitSeccion: EmitEjecuta,
            },
            Filtro:{
                Nombre: '',
                Pagina: 1,
                Entrada: 25,
                TotalItem: 0,
                Placeholder: 'Buscar..',
            },
            Emit: this.poBtnSave.EmitSeccion,
            errorvalidacion: [],
            ListaArrayRows: [],
            ListaHeader: [],
            Prestamo: {
            },
            Campos: {
                Fecha: '',
                Pago: '',
                IdPrestamosPago: 0,
                IdPrestamo: 0,
                Estatus: '',
                DiaActual: '',
            },
            segurity: {},
            obj: {},
        }
    },
    methods:{
        async Lista()
        {
            this.ConfigList.ShowLoader = true;

            await this.$http.get('prestamosmultas', {
                params:{
                    TxtBusqueda: this.Filtro.Nombre,
                    Entrada:     this.Filtro.Entrada,
                    Pag:         this.Filtro.Pagina,
                    IdPrestamo:  this.Prestamo.IdPrestamo,
                }
            }).then( (res) => {
                this.ListaArrayRows   = res.data.data.data;
                this.Filtro.Pagina    = res.data.data.current_page;
                this.Filtro.TotalItem = res.data.data.total;
                this.segurity.Delete = 1;
            }).finally(()=>{
                this.ConfigList.ShowLoader = false;
            });
        },
        Eliminar(Id) {
            this.$swal(Configs.configEliminar).then((result) => {
                if(result.value) {
                    this.$http.delete('prestamosmultas/'+Id)
                    .then( (res) => {
                        this.$swal(Configs.configEliminarConfirm);
                        this.Lista();
                    })
                    .catch( err => {
                        this.$toast.error(err.response.data.message);
                    });
                }
            });
        },
        SaveMulta(item)
        {
            let obj = {
                IdMulta: item.IdPrestamoMulta,
                FechaEmision: item.FechaEmision,
            }

            this.$http.put('prestamosmultas/'+obj.IdMulta,
                obj
            ).then((res)=>{                 
                this.$toast.success('Información Guardada');
            }).catch(err=>{   
                this.$toast.error(err.response.data.message);
            });
        },
        GenerarDia()
        {
            let formData = new FormData();
            formData.set('Fecha', this.Campos.Fecha);
            formData.set('IdPrestamo', this.Prestamo.IdPrestamo);
            formData.set('IdRuta', this.Prestamo.IdRuta);

            this.$http.post('setNuevoDia', formData)
            .then((res)=>{
                this.Campos.IdPrestamosPago = res.data.Cobranza.IdCobranzaDiaria;
                this.Campos.DiaActual = res.data.Cobranza.NumDia;
                this.Campos.IdPrestamo = res.data.data.IdPrestamo;
                this.Campos.Pago = '';
            }).catch(err=>{
                this.$toast.error(err.response.data.message);
            }).finally(()=>{
                this.Campos.Estatus = 'Dia generado';
            });
        },
        PagarDia()
        {
            this.$http.get('setPagoDia', {
                params:{
                    IdPrestamo: this.Prestamo.IdPrestamo,
                    IdPrestamosPago: this.Campos.IdPrestamosPago,
                    MontoCobrado: this.Campos.Pago,
                }
            }).then( (res) => {
                this.bus.$emit('recupera');
                
            }).finally(()=>{
                this.Campos.Estatus = 'pago realizado';
            });
        },
        CerrarDia()
        {
            this.$http.get('setCierreDia', {
                params:{
                    IdRuta: this.Prestamo.IdRuta,
                    Fecha: this.Campos.Fecha,
                    IdPrestamo: this.Prestamo.IdPrestamo,
                }
            }).then( (res) => {
                this.bus.$emit('recupera');
                
            }).finally(()=>{
                this.Campos.Estatus = 'Corte generado';
            });
        },
    },
    created(){
        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(obj)=>
        {
            console.log(obj)
            this.bus.$off('Delete_'+EmitEjecuta);

            this.bus.$on('Delete_'+EmitEjecuta,(Id)=> {
                this.Eliminar(Id);
            });

            this.Prestamo = obj
            //this.Lista();
        });
    },
}

</script>
