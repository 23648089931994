<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <div class="form-row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="form-group form-row">
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            <label><b>Cliente:&nbsp;</b></label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                            <label class="text-dark">{{objPago.NombreCompleto}}</label>
                        </div>
                    </div>

                    <div class="form-group form-row">
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            <label><b>Folio:&nbsp;</b></label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                            <label class="text-dark">{{objPago.Folio}}</label>
                        </div>
                    </div>

                    <div class="form-group form-row">
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            <label><b>Saldo:&nbsp;</b></label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                            <label class="text-dark">{{$formatNumber(objPago.Saldo, '$')}}</label>
                        </div>
                    </div>

                    <div class="form-group form-row">
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            <label><b>Pago:&nbsp;</b></label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                            <label class="text-dark">{{$formatNumber(objPago.MontoDiario, '$')}}</label>
                        </div>

                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <label><b>Monto Pagado:&nbsp;</b></label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                            <label class="text-dark">{{$formatNumber(objPago.MontoRealPagado, '$')}}</label>
                        </div>
                    </div>

                    <div class="form-group form-row">
                        
                    </div>

                    <div class="form-group form-row">
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            <label class="col-form-label">Monto:&nbsp;</label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <input type="text" v-model="objSend.MontoCobrado" @input="$number_decimal($event,objSend,'MontoCobrado')" class="form-control">
                            <CValidation v-if="this.errorvalidacion.MontoCobrado" :Mensaje="'*'+errorvalidacion.MontoCobrado[0]"/>
                        </div>

                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <label class="mt-1"><b>Es Liquidación:&nbsp;</b></label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            <b-form-checkbox v-model="renovacion" name="check-button" id="item20" class="mt-1" :value="1" switch>
                                <b class="text-dark" v-if="renovacion>0">SI</b>
								<b class="text-dark" v-else>NO</b>
                            </b-form-checkbox>
                        </div>
                    </div>

                    <div class="form-group form-row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <label><b>Justificante de la Corrección:&nbsp;</b></label>
                            <textarea v-model="objSend.ComentarioPago" class="form-control" rows="3"></textarea>
                            <CValidation v-if="this.errorvalidacion.ComentarioPago" :Mensaje="'*'+errorvalidacion.ComentarioPago[0]"/>
                        </div>
                    </div>

                </div>
            </div>
        </template>
    </CLoader>
</template>

<script>

export default {
    name: "FormPagos",
    props:["poBtnSave"],
    components:{
    },
    data() {
        return {
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad:  false,
            },
            errorvalidacion: [],
            Emit: this.poBtnSave.EmitSeccion,
            objPago: {
            },
            objSend: {
                IdPrestamo: 0,
                IdPrestamosPago: 0,
                MontoCobrado: '',
                PosicionCobro: 0,
                Latitud: 0,
                Longitud: 0,
                ComentarioPago: '',
                checkLiquidacion: false,
                folioGeneric: '',
            },
            renovacion: ''
        }
    },

    methods: {
        async Guardar()
        {
            this.errorvalidacion = [];
            this.poBtnSave.toast = 0; 
            this.poBtnSave.DisableBtn = true;

            this.objSend.IdPrestamo         = this.objPago.IdPrestamo;
            this.objSend.IdPrestamosPago    = this.objPago.IdPrestamosPago;
            this.objSend.checkLiquidacion   = (this.renovacion !=1)?0:this.renovacion;
            this.objSend.Latitud            = this.objPago.LatitudPago;
            this.objSend.Longitud           = this.objPago.LongitudPago;
            this.objSend.folioGeneric       = this.objPago.folioGeneric;
            
            let MontoDiario  = this.objPago.MontoDiario;
            let MontoCobrar = (this.objSend.MontoCobrado == '')?0:this.objSend.MontoCobrado;
            let MontoCobrado = this.$formatNumber(MontoCobrar,'$');

            this.$swal(
            {
                title: '<h2 style="font-size:22px; font-weight: 700;">¿Estas seguro de qué el pago por: '+MontoCobrado+' correspondiente a $'+MontoDiario+' es correcto?</h2>',
                text: 'Esta acción no se podra revertir ',
                icon: 'warning',
                showCancelButton:    true,
                confirmButtonText:   'Continuar',
                cancelButtonText:    'Cancelar',
                showCloseButton:     true,
                showLoaderOnConfirm: true,
                confirmButtonColor:  '#0096ED',
                cancelButtonColor:   '#b92e27',
            }).then((result) => {
                if(result.value) {
                    this.$http.post('correccionPago',this.objSend).then((res)=>{                
                        this.poBtnSave.DisableBtn = false;
                        this.EjecutaConExito(res);
                    }).catch(err=>{   
                        this.EjecutaConError(err);
                    });
                } else {
                    this.poBtnSave.DisableBtn = false;
                }
            });
        },
        EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;  
            this.poBtnSave.toast = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_seccionCliente');
            this.bus.$emit('List_seccionCortedeDia');
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;
            
            if(err.response.data.error){
                this.errorvalidacion = err.response.data.error;
                this.poBtnSave.toast = 2;
            }
            else{
                this.$toast.error(err.response.data.message);
            }
        },
        Limpiar()
        {
            this.objPago = {};
            this.objSend = {
                IdPrestamo: 0,
                IdPrestamosPago: 0,
                MontoCobrado: '',
                PosicionCobro: 0,
                Latitud: 0,
                Longitud: 0,
                ComentarioPago: '',
                folioGeneric: '',
            };
            this.errorvalidacion = [];
        },
    },
    created() {
        this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(obj)=>
        {
            this.ConfigLoad.ShowLoader = true;    
            this.poBtnSave.DisableBtn = false;

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,()=>
            {
                this.Guardar();
            });
            this.Limpiar();
            this.objPago = obj;

            if(this.objPago.EsLiquidacion == 'SI'){
                this.renovacion = 1;
            }
                
            this.ConfigLoad.ShowLoader = false;
        });
    },
}
</script>
