<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <div class="form-row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="form-group form-row">
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            <label><b>Cliente:&nbsp;</b></label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                            <label class="text-dark">{{objPago.NombreCompleto}}</label>
                        </div>
                    </div>

                    <div class="form-group form-row">
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            <label><b>Folio:&nbsp;</b></label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                            <label class="text-dark">{{objPago.Folio}}</label>
                        </div>
                    </div>

                    <div class="form-group form-row">
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            <label><b>Saldo:&nbsp;</b></label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                            <label class="text-dark">{{$formatNumber(objPago.Saldo, '$')}}</label>
                        </div>
                    </div>

                    <div class="form-group form-row">
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            <label><b>Pago:&nbsp;</b></label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                            <label class="text-dark">{{$formatNumber(objPago.MontoDiario, '$')}}</label>
                        </div>
                    </div>


                    <div class="form-group form-row">
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            <label class="col-form-label">Monto:&nbsp;</label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <input type="text" v-model="objSend.MontoCobrado" @input="$number_decimal($event,objSend,'MontoCobrado')" class="form-control">
                            <CValidation v-if="this.errorvalidacion.MontoCobrado" :Mensaje="'*'+errorvalidacion.MontoCobrado[0]"/>
                        </div>

                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <label class="mt-1"><b>Es Liquidación:&nbsp;</b></label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            <b-form-checkbox v-model="renovacion" name="check-button" id="item20" class="mt-1" :value="1" switch>
                                <b class="text-dark" v-if="renovacion>0">SI</b>
								<b class="text-dark" v-else>NO</b>
                            </b-form-checkbox>
                        </div>
                    </div>

                    <div class="form-group form-row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <label><b>Comentarios:&nbsp;</b></label>
                            <textarea v-model="objSend.ComentarioPago" class="form-control" rows="3"></textarea>
                            <CValidation v-if="this.errorvalidacion.ComentarioPago" :Mensaje="'*'+errorvalidacion.ComentarioPago[0]"/>
                        </div>
                    </div>

                </div>
            </div>
        </template>
    </CLoader>
</template>

<script>

export default {
    name: "FormPagos",
    props:["poBtnSave"],
    components:{
    },
    data() {
        return {
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad:  false,
            },
            errorvalidacion: [],
            Emit: this.poBtnSave.EmitSeccion,
            objPago: {
            },
            objSend: {
                IdPrestamo: 0,
                IdPrestamosPago: 0,
                MontoCobrado: '',
                PosicionCobro: 0,
                Latitud: 0,
                Longitud: 0,
                ComentarioPago: '',
                IdRuta: 0,
                folioGeneric: '',
            },
            checkLiquidacion: false,
            renovacion: ''
        }
    },

    methods: {
        async Guardar()
        {
            this.errorvalidacion = [];
            this.poBtnSave.toast = 0; 
            this.poBtnSave.DisableBtn = true;

            let PosicionCobro = 1;
            let session = sessionStorage.getItem('PosicionCobro');

            if(session === null){
                sessionStorage.setItem('PosicionCobro',1);
            }
            else
            {
                PosicionCobro = parseInt(session)+1;
            }

            this.objSend.IdPrestamo      = this.objPago.IdPrestamo;
            this.objSend.IdPrestamosPago = this.objPago.IdPrestamosPago;
            this.objSend.PosicionCobro   = PosicionCobro;
            this.objSend.folioGeneric    = '';

            this.checkLiquidacion = (this.renovacion !=1)?0:this.renovacion;
            
            // SI EL CHECK DE LIQUIDACION ESTA ACTIVO SE LIQUIDA, SINO SOLO SE REALIZA EL PAGO
            if(this.checkLiquidacion == 1)
            {
                let MontoCubierto = this.objSend.MontoCobrado;
                let MontoCobrar = (MontoCubierto == '')?0:MontoCubierto;

                let newObjSend = {
                    IdPrestamo:         this.objPago.IdPrestamo,
                    IdPrestamosPago:    this.objPago.IdPrestamosPago,
                    IdCobratario:       0,
                    IdSucursal:         0,
                    IdCliente:          0,
                    MontoSolicitud:     0,
                    Origen:             'web',
                    MontoCubierto:      MontoCubierto,
                    ComentarioPago:     this.objSend.ComentarioPago,
                    Observaciones:      '',
                    Latitud:            this.objSend.Latitud,
                    Longitud:           this.objSend.Longitud,
                }
                console.log(newObjSend);

                
                MontoCubierto = this.$formatNumber(MontoCobrar,'$');

                this.$swal({ 
                    title: '<h2 style="font-size:23px; font-weight: 700;">¿Estas seguro de qué desea liquidar con la cantidad de: '+MontoCubierto+'?</h2>',
                    text: 'Esta acción no se podra revertir ',
                    icon: 'warning',
                    showCancelButton:    true,
                    confirmButtonText:   'Continuar',
                    cancelButtonText:    'Cancelar',
                    showCloseButton:     true,
                    showLoaderOnConfirm: true,
                    confirmButtonColor:  '#0096ED',
                    cancelButtonColor:   '#b92e27',
                }).then((result) => {

                    if(result.value) {
                        this.$http.post('liquidacionprestamo',newObjSend).then((res)=>{                
                            this.EjecutaConExito(res);
                        }).catch(err=>{   
                            this.EjecutaConError(err);
                        });
                    } else {
                        this.poBtnSave.DisableBtn = false;
                    }
                });
            }
            else
            {
                let MontoDiario  = this.objPago.MontoDiario;
                let MontoCobrar = (this.objSend.MontoCobrado == '')?0:this.objSend.MontoCobrado;
                let MontoCobrado = this.$formatNumber(MontoCobrar,'$');

                this.$swal(
                {
                    title: '<h2 style="font-size:22px; font-weight: 700;">¿Estas seguro de qué el pago por: '+MontoCobrado+' correspondiente a $'+MontoDiario+' es correcto?</h2>',
                    text: 'Esta acción no se podra revertir ',
                    icon: 'warning',
                    showCancelButton:    true,
                    confirmButtonText:   'Continuar',
                    cancelButtonText:    'Cancelar',
                    showCloseButton:     true,
                    showLoaderOnConfirm: true,
                    confirmButtonColor:  '#0096ED',
                    cancelButtonColor:   '#b92e27',
                }).then((result) => {
                    if(result.value) {
                        this.$http.post('cobrarpago',this.objSend).then((res)=>{                
                            sessionStorage.setItem('PosicionCobro',PosicionCobro); 
                            this.poBtnSave.DisableBtn = false;
                            this.EjecutaConExito(res);
                        }).catch(err=>{   
                            this.EjecutaConError(err);
                        });
                    } else {
                        this.poBtnSave.DisableBtn = false;
                    }
                });
            }
        },
        EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;  
            this.poBtnSave.toast = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_seccionCliente');
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;
            
            if(err.response.data.error){
                this.errorvalidacion = err.response.data.error;
                this.poBtnSave.toast = 2;
            }
            else{
                this.$toast.error(err.response.data.message);
            }
        },
        Recuperar ()
        {
            this.$http.get(
                "sucursales/"+this.objPago.IdSucursal
            ).then( (res) => {
                let response = res.data.data;

                this.objSend.Latitud = response.Latitud;
                this.objSend.Longitud = response.Longitud;

            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            });
        },
        Limpiar()
        {
            this.objPago = {};
            this.objSend = {
                IdPrestamo: 0,
                IdPrestamosPago: 0,
                MontoCobrado: '',
                PosicionCobro: 0,
                Latitud: 0,
                Longitud: 0,
                ComentarioPago: '',
                IdRuta: 0,
                folioGeneric: '',
            };
            this.errorvalidacion = [];
        },
    },
    created() {
        this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(obj)=>
        {
            this.ConfigLoad.ShowLoader = true;    
            this.poBtnSave.DisableBtn = false;

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,()=>
            {
                this.Guardar();
            });
            this.Limpiar();
            this.objPago = obj
                
            if(this.objPago.IdSucursal!='') 
            {
                this.Recuperar();
            }
            else
            {
                this.ConfigLoad.ShowLoader = false;
            }
        });
    },
}
</script>
